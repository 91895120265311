<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="邮箱帐号" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="send_email">
            <a-textarea :auto-size="{ minRows: 1,}" v-model="form.send_email" placeholder="请输入邮箱帐号..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="邮箱类型" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="server_type">
            <a-select v-model="form.server_type" placeholder="请选择邮箱类型..." :options='emailType'>
            </a-select>
          </a-form-model-item>
        </a-col>
       

        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-popconfirm :title="'是否删除'+selectedRowKeys.length+'条信息?'" @confirm="() => onDelete(data,'batch')" style="margin-right:10px" :disabled="!selectedRowKeys.length > 0">
        <a-button type="danger"  :disabled="!selectedRowKeys.length > 0">批量删除</a-button type="primary">
      </a-popconfirm>

      <a-button type="primary" @click="onAdd">
        添加
      </a-button>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
      
        <template slot="is_ssl" slot-scope="data">

            {{data==0?'否':'是'}}

        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span class="narrow">
                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>
                <a-popconfirm title="是否删除此信息?" @confirm="() => onDelete(data)">
                  <a-button size="small" type="danger">删除</a-button type="primary" >
                </a-popconfirm>
              </span>

        </template>
      </a-table>
    </div>


    <!-- 修改帐号信息模态框 -->
    <a-modal
      :maskClosable="false"
      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="saveUser"
      @cancel="cancelUser"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="user"
        :model="userForm"
        :rules="userFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >

      <a-form-model-item label="邮箱帐号" prop="send_email">
        <a-input v-model="userForm.send_email" placeholder="请输入邮箱帐号"/>
      </a-form-model-item>
      <a-form-model-item label="邮箱密码" prop="password">
        <a-input v-model="userForm.password" placeholder="请输入邮箱密码"/>
      </a-form-model-item>
      <a-form-model-item label="服务器名称" prop="server_host">
        <a-input v-model="userForm.server_host" placeholder="请输入服务器名称"/>
      </a-form-model-item>
      <a-form-model-item label="服务器端口" prop="server_port">
        <a-input v-model="userForm.server_port" placeholder="请输入服务器端口"/>
      </a-form-model-item>

      <a-form-model-item label="是否开启SSL" prop="is_ssl">
        <a-radio-group v-model="userForm.is_ssl" name="is_ssl">
          <a-radio value="1">
            是
          </a-radio>
          <a-radio value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="邮箱类型" prop="server_type">
        <a-radio-group v-model="userForm.server_type" name="server_type">
          <a-radio value="pop3">
            pop3
          </a-radio>
          <a-radio value="imap">
            imap
          </a-radio>
        </a-radio-group>
      </a-form-model-item>


      </a-form-model>
    </a-modal>



  </div>
</template>
<script>
import {getEmailList,addEmail,deleteEmail,editEmail} from "@/axios/email.js"

import {getCookie} from '@/static/js/cookie';
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述
  {
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },
  {
    align:'center',
    title: '邮箱帐号',
    dataIndex: 'send_email',
  },
  {
    align:'center',
    title: '邮箱密码',
    dataIndex: 'password',
  },
  {
    align:'center',
    title: '邮箱类型',
    dataIndex: 'server_type',
  },
  {
    align:'center',
    title: '邮箱服务器名称',
    dataIndex: 'server_host',
  },
  {
    align:'center',
    title: '邮箱端口',
    dataIndex: 'server_port',
  },
  {
    align:'center',
    title: '邮箱SSL',
    dataIndex: 'is_ssl',
    scopedSlots: { customRender: 'is_ssl', },
  },
  {
    align:'center',
    title: '创建时间',
    dataIndex: 'create_time',
  },
  {
    align:'center',
    title: '更新时间',
    dataIndex: 'update_time',
  },

  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    // roleFilter(status) {
    //   const statusMap = {
    //     'super': '超级管理员',
    //     'normal': '供应商',
    //     'agent':'代理'
    //   }
    //   return statusMap[status]
    // },
  },
  mixins: [myMixin],
  data() {

    return {

      emailType: [
        {
            value: '',
            label: '请选择邮箱类型'
        },
        {
            value: 'pop3',
            label: 'pop3'
        },
        {
            value: 'imap',
            label: 'imap'
        },
      ],



      form: {
        page_size: 10,
        page:1,
        send_email:'',
        server_type:'',

      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      userForm:{
        id:'',
        send_email:'',
        server_host:'',
        server_port:'',
        is_ssl:'',
        server_type:'',
        password:'',

      },
      userFormRules: {
        
        send_email: [
          { required: true, message: '请输入邮箱账户', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入邮箱密码', trigger: 'blur' },
          { message: '密码长度为6-18', trigger: 'blur',min:6,max:18 },
        ],
        server_host: [
          { required: true, message: '请输入服务器名称', trigger: 'blur' },
        ],
        server_port: [
          { required: true, message: '请输入服务器端口', trigger: 'blur' },
        ],
        is_ssl: [
          { required: true, message: '请选择是否开启SSL', trigger: 'change' },
        ],
        server_type: [
          { required: true, message: '请选择账户类型', trigger: 'change' },
        ],
  

      },

      title:'添加邮箱配置',

    }

  },
  mounted() {
    let that=this

    this.fetch();
  },
  methods:{
 

      async fetch (params = {}) {
        this.loading = true;

        let data=await getEmailList({...params,...this.form})
        const pagination = { ...this.pagination };
          pagination.total = data.data.total;
          this.selectedRowKeys=[];
          this.data = data.data.list;
          this.pagination = pagination;
          console.log(data,this.pagination,pagination,'data')
    
        this.loading = false;
        
      },

      //添加商户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加邮箱配置'
      },

      //删除数据
      async onDelete(data,type){

        let resule=await deleteEmail({config_id:type=='batch'?this.selectedRowKeys.join(','):data.id});
          if(resule.code==200){
            this.$message.success(resule.msg);
            this.fetch()
          }else{
            this.$message.error(resule.msg);
          }
      },

      //编辑保存账户数据
      onEdit(data){
        this.title='编辑邮箱配置'
        this.editModalVisible=true
        this.$nextTick(() => { // 不用nextTick的话，清空数据时会有问题
          for (let key in this.userForm) {
            this.userForm[key] = data[key];
          }
          console.log(this.userForm,this.userForm.bw_switch,data.bw_switch,'点编辑时的信息')
        });


      },
      
      //更新支付方式
      saveUser(){
        this.$refs.user.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title=='添加邮箱配置'){
            resule=await addEmail(this.userForm);
          }else{
            resule=await editEmail({config_id:this.userForm.id,...this.userForm});
          }
          

          if(resule.code==200){
            this.$message.success(resule.msg);
            this.editModalVisible = false;
            this.$refs.user.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.msg);
          }
          this.confirmLoading = false;
        } else {
          return false;
        }
      });

      },

      cancelUser(){
        this.$refs.user.resetFields();
        console.log(this.userForm,'userForm')
        this.editModalVisible = false;
      },


     
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }

::v-deep .ant-table-bordered .ant-table-thead > tr > th{    word-break: inherit;}
::v-deep .ant-modal-body {

    max-height: 600px;
    overflow-x: auto;
}
</style>
