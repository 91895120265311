
import {get,post} from "@/axios";



export function getEmailList(data) {
  return get('/api/configs/list',data);

}
export function addEmail(data) {
  return post('/api/configs/add',data);

}
export function deleteEmail(data) {
  return post('/api/configs/delete',data);

}

export function editEmail(data) {
  return post('/api/configs/edit',data);

}



